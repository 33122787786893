import { Environment } from "../enums";

const env = {
  API_URL: "http://localhost:8082/api",
  Chat_API: "https://pandora.maybetech.com/api",
  Chat_Auth: "a71860da-1a58-4797-90f1-02dcb5fd06b1",
  New_Chat_ENV: "/chat",
  Chat_MSG_ENV: "/chat/message",
  APP_ENV: "PROD",
};

if (process.env.REACT_APP_ENV === Environment.DEVELOPMENT) {
  env.API_URL = "https://betachatapi.maybetech.com/api";
  env.Chat_API = "https://dev-pandora.maybetech.com/api";
  env.Chat_Auth = "19e20c56-3355-422d-96d0-97706036ddfd";
  env.New_Chat_ENV = "/chat";
  env.Chat_MSG_ENV = "/chat/message";
  env.APP_ENV = "DEV";
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = "https://apichat.maybetech.com/api";
  env.Chat_API = "https://pandora.maybetech.com/api";
  env.Chat_Auth = "a71860da-1a58-4797-90f1-02dcb5fd06b1";
  env.New_Chat_ENV = "/chat";
  env.Chat_MSG_ENV = "/chat/message";
  env.APP_ENV = "PROD";
}

export default env;
