import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import env from "config";

const Data = () => {
  const pathname = window.location.pathname;
  const loc_id = pathname.split("/")[1];
  const BASE_URL = `${env.API_URL}/v1`;
  const APP_ENV = env.APP_ENV;
  const [data, setData] = useState([]);
  const [processing, setProcessing] = useState(true);

  const GetChats = async () => {
    setProcessing(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/chat/filter?locationId=${loc_id}`
      );
      setData(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  const ChatSubmit = async (payload, fromPrompt = false) => {
    payload.env = APP_ENV;
    return await axios
      .post(BASE_URL + "/chat", {
        ...payload,
      })
      .then(async function (response) {
        var chat_data = {};
        chat_data["Welcome " + response.data.data.username] = [];
        const chat = {
          id: response.data.data.conversation_id,
          name: "",
          whatsapp_name: response.data.data.username,
          messages: chat_data,
          welcome_array: "Welcome " + response.data.data.username,
          fromPrompt: fromPrompt,
        };

        return chat;
      })
      .catch(function (error) {
        return error;
      });
  };

  useEffect(async () => {
    GetChats();
  }, []);

  return {
    data,
    processing,
    handleRefreshData: GetChats,
    ChatSubmit,
    GetChats,
  };
};

export default Data;
