import React from "react";
import "./styles/main.css";
import Icon from "../Icon";
import maybe from "assets/images/maybe.png";
const Loader = ({ done }) => {
  return (
    <div className="loader">
      <div className="loader__logo-wrapper">
        <img src={maybe} alt="" />
      </div>
      <div
        className={`loader__progress ${done ? "loader__progress--done" : ""}`}
      ></div>
      <h1 className="loader__title"> Personalised AI</h1>
      <p className="loader__desc">
        <Icon id="lock" className="loader__icon" />
        End-to-end encrypted.
      </p>
    </div>
  );
};

export default Loader;
