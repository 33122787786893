import Icon from "components/Icon";
import React, { useRef, useState } from "react";
import SubCategoryAccordion from "./SubCategoryAccordion";

//  accordionitem component
const AccordionItem = ({ question, subCategories, isOpen, onClick, cate_id, userId, prompts, ChatSubmit }) => {
    const contentHeight = useRef();
    return (
        <div className={`catWrapper ${isOpen ? "activeWrapper" : ""}`}>
            <button
                className={`question-container ${isOpen ? "active" : ""}`}
                onClick={onClick}
            >
                <p className="question-content parentCategory">{question}</p>
                {isOpen ?
                    <Icon id="upArrow" className="chat__input-icon" /> :
                    <Icon id="downArrow" className="chat__input-icon" />}
                {/* <RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`} /> */}
            </button>

            <div
                ref={contentHeight}
                className="answer-container subCatAnswer"
                style={
                    isOpen
                        ? { height: "fit-content" }
                        : { height: "0px" }
                }
            >
                <div className="answer-content">
                    <SubCategoryAccordion subCategories={subCategories} cat_id={cate_id} prompts={prompts} userId={userId} ChatSubmit={ChatSubmit} />
                </div>
            </div>
        </div>
    );
};

const CategoryAccordion = ({ promptsCategories, promptsSubCategories, userId, prompts, ChatSubmit }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            {promptsCategories.map((item, index) => (
                <>
                    <AccordionItem
                        key={index}
                        question={item.name}
                        subCategories={promptsSubCategories}
                        prompts={prompts}
                        cate_id={item._id}
                        isOpen={activeIndex === index}
                        userId={userId}
                        onClick={() => handleItemClick(index)}
                        ChatSubmit={ChatSubmit}
                    />
                </>
            ))}
        </>
    );
};

export default CategoryAccordion;
