import React, { useEffect, useRef, useState } from "react";
import "./styles/main.css";
import ChatInput from "./components/ChatInput";
import ChatSidebar from "./components/ChatSidebar";
import Icon from "components/Icon";
import Convo from "./components/Convo";
import { useUsersContext } from "context/usersContext";
import Header from "./components/Header";
import Prompts from "./components/Prompts";
import { useLocation } from "react-router-dom";

const Chat = ({ match, history }) => {
  const { users, setUserAsUnread, addNewMessage, showLeftSidebar, hideLeftSidebar, isActiveSide } = useUsersContext();

  const { pathname } = useLocation();
  const loc_id = pathname.split("/")[1];
  const loc_name = pathname.split("/")[2];
  const username = pathname.split("/")[3];

  const userId = match.params.id;
  let user = users.filter((user) => user.id === userId)[0];
  // if (user === undefined) {
  //   const redirectUrl = `/${loc_id}/${loc_name}/${username}`;
  //   history.push(redirectUrl);
  // }

  const lastMsgRef = useRef(null);
  const [showAttach, setShowAttach] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showProfileSidebar, setShowProfileSidebar] = useState(false);
  const [showSearchSidebar, setShowSearchSidebar] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [footerHeight, setFooterHeight] = useState('64px');
  const [goBottomBtn, setGoBottomBtn] = useState('120px');

  useEffect(() => {
    console.log(user);
    if (!user) {
      const redirectUrl = `/${loc_id}/${loc_name}/${username}`;
      history.push(redirectUrl);
    } else {
      scrollToLastMsg();
      setUserAsUnread(user.id);
    }
  }, []);

  useEffect(() => {
    if (user) {
      scrollToLastMsg();
    }
  }, [users, user]);

  useEffect(() => {
  }, [isActiveSide])

  useEffect(() => {
    if (footerHeight) {
      const newHeight = parseInt(footerHeight.replace(/\D/g, '')) + 56 + 'px';
      setGoBottomBtn(newHeight)
      console.log(footerHeight);
    }
  }, [footerHeight])

  const openSidebar = (cb) => {
    setShowProfileSidebar(false);
    setShowSearchSidebar(false);
    hideLeftSidebar();

    // call callback fn
    cb(true);
  };

  const closeSidebar = () => {
    setShowSearchSidebar(false)
    showLeftSidebar();
  }

  const scrollToLastMsg = () => {
    if (lastMsgRef.current) {
      // alert("diyh");
      lastMsgRef.current.scrollIntoView();
    }
  };

  const submitNewMessage = () => {
    addNewMessage(user.id, newMessage);
    setNewMessage("");
    scrollToLastMsg();
  };

  return (
    <div className="chat">
      <div className="chat__body">
        <div className="chat__bg"></div>
        <div className="chat__content">
          {user && (
            <Convo
              userId={userId}
              lastMsgRef={lastMsgRef}
              messages={user.messages}
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              submitNewMessage={submitNewMessage}
            />
          )}
        </div>
        <footer className="chat__footer">
          <button
            style={{ bottom: goBottomBtn }}
            className="chat__scroll-btn"
            aria-label="scroll down"
            onClick={scrollToLastMsg}
          >
            <Icon id="downArrow" />
          </button>
          <ChatInput
            showEmojis={showEmojis}
            setShowEmojis={setShowEmojis}
            showAttach={showAttach}
            setShowAttach={setShowAttach}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            submitNewMessage={submitNewMessage}
            setFooterHeight={setFooterHeight}
            footerHeight={footerHeight}
          />
        </footer>
      </div>
    </div>
  );
};

export default Chat;
