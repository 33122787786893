import React, { useEffect, useState } from "react";
import "./styles/main.css";
import avatar from "assets/images/profile-picture-girl-1.jpeg";
import Icon from "components/Icon";
import Alert from "./Alert";
import Contact from "./Contact";
import OptionsBtn from "components/OptionsButton";
import { useUsersContext } from "context/usersContext";
import ChatModal from "./components/ChatModal";
import { useLocation } from "react-router-dom";
import Prompts from "pages/Chat/components/Prompts";

const Sidebar = () => {
  const { users: contacts, locValid, isActiveSide, handleDeleteContact, processing, showExploreContent, toggleExploreContent } = useUsersContext();

  // const [showExploreContent, setShowExploreContent] = useState(false);

  // const handleExplorePromots = () => {
  //   setShowExploreContent(!showExploreContent);
  // };

  const { pathname } = useLocation();
  const userId = pathname.split("/")[5];

  return (
    <>
      {locValid ? (
        <aside className={`sidebar ${!isActiveSide ? 'dNone' : ''}`}>
          <header className="header">
            <ChatModal processing={processing} onExplorePromots={toggleExploreContent} showExploreContent={showExploreContent} />
          </header>
          {showExploreContent ? (
            <div className="sidebar__explore-content">
              <Prompts userId={userId} />
            </div>
          ) : (
            <div className="sidebar__contacts">
              {processing ? (
                <div className="chatSpinner"></div>
              ) : (
                contacts.map((contact, index) => (
                  <Contact key={index} contact={contact} onDelete={handleDeleteContact} />
                ))
              )}
            </div>
          )}
        </aside>
      ) : (
        <></>
      )}
    </>
  );
};

export default Sidebar;
