import Icon from "components/Icon";
import { useUsersContext } from "context/usersContext";
import { useLocation, useHistory } from "react-router-dom";
import React, { useRef, useState } from "react";

//  accordionitem component
const AccordionItem = ({ question, prompts, isOpen, onClick, sub_cate_id, userId, ChatSubmit }) => {
    const history = useHistory();
    const { addNewMessage, setUserAsUnread, refresh, showToast, toggleExploreContent, setOnFromPrompt } = useUsersContext();
    const { pathname } = useLocation();
    const loc_id = pathname.split("/")[1];
    const loc_name = pathname.split("/")[2];
    const username = pathname.split("/")[3];

    const handleSubmit = async (e, user_msg, prompt) => {
        e.preventDefault();
        setOnFromPrompt(userId);
        const payload = {
            locationId: loc_id,
            username: username,
        };

        const temp = await ChatSubmit(payload, true);

        if (!temp?.response) {
            refresh(temp);

            const chatUrl = `/${loc_id}/${loc_name}/${username}/chat/${temp.id}`;
            setUserAsUnread(temp.id);
            history.push(chatUrl);

            // const SubmitPrompt = (user_msg, prompt) => {
            addNewMessage(temp.id, user_msg, prompt);
            // };
        } else {
            showToast("Something went wrong!");
        }
    };
    const contentHeight = useRef();
    return (
        <div className="wrapper subCategoryWrapper">
            <button
                className={`question-container ${isOpen ? "active" : ""}`}
                onClick={onClick}
            >
                <p className="question-content">{question}</p>
                {isOpen ?
                    <Icon id="upArrow" className="chat__input-icon" /> :
                    <Icon id="downArrow" className="chat__input-icon" />}
                {/* <RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`} /> */}
            </button>

            <div
                ref={contentHeight}
                className="answer-container"
                style={
                    isOpen
                        ? { height: "fit-content" }
                        : { height: "0px" }
                }
            >
                <div className="answer-content">
                    {prompts.map((item, index) => (
                        <>
                            {item.sub_category == sub_cate_id ?
                                <div className="prompt_box_sidebar"
                                    onClick={(event) => handleSubmit(event, item.title, item.prompt_value)} >
                                    <div className="prompt_text">
                                        {item.title}
                                    </div>
                                    <Icon id="send" className="chat__input-icon prompt_icon" />
                                </div>
                                :
                                ''}
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};

const SubCategoryAccordion = ({ subCategories, cat_id, userId, prompts, ChatSubmit }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            {subCategories.map((item, index) => (
                <>
                    {item.category_id == cat_id ?
                        <AccordionItem
                            key={index}
                            question={item.name}
                            prompts={prompts}
                            sub_cate_id={item._id}
                            isOpen={activeIndex === index}
                            userId={userId}
                            onClick={() => handleItemClick(index)}
                            ChatSubmit={ChatSubmit}
                        />
                        :
                        <></>
                    }
                </>
            ))}
        </>
    );
};

export default SubCategoryAccordion;
