import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./assets/css/index.css";
import { UsersProvider } from "context/usersContext";
import { PromptsProvider } from "context/promptsContext";
// import { SocketProvider } from "context/socketContext";

ReactDOM.render(
  <React.StrictMode>
    <UsersProvider>
      <PromptsProvider>
        <App />
      </PromptsProvider>
    </UsersProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
