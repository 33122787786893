// promptsContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import env from "config";

export const PromptsContext = createContext();

export const PromptsProvider = ({ children }) => {
  const BASE_URL = `${env.API_URL}/v1`;
  const [promptsData, setPromptsData] = useState(null);
  const [promptsError, setPromptsError] = useState();

  const getPrompts = async () => {
    try {
      const response = await axios.get(BASE_URL + "/prompts/prompt_data");
      setPromptsData(response.data.data);
    } catch (error) {
      setPromptsError(error);
    }
  };

  useEffect(() => {
    getPrompts();
  }, []);

  return (
    <PromptsContext.Provider value={{ promptsData, promptsError }}>
      {children}
    </PromptsContext.Provider>
  );
};
